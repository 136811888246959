import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProyectosService } from '../../services/proyectos.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public form: FormGroup;
  constructor(private fb: FormBuilder, private proyectosService: ProyectosService) { }

  ngOnInit() {
    this.form = this.fb.group({
      nombre: ['', Validators.required],
      telefono: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      mensaje: ['', Validators.required],
      presupuesto: ['', Validators.required]
    });
  }
  enviarContacto() {
    let form: any = JSON.stringify(this.form.value);
    try {
      if(!this.form.value.nombre) { throw "Ingresa tu nombre completo"; }
      if(!this.form.value.correo) { throw "Ingresa tu correo"; }
      if(!this.form.value.telefono) { throw "Ingresa tu teléfono"; }
      if(!this.form.value.mensaje) { throw "Ingresa tu mensaje o duda"; }

      this.proyectosService.enviarFormulario(form)
      .subscribe(res => {
        this.proyectosService.showToastr('success', 'Se ha enviado tu solicitud, nos pondremos en contacto contigo.');
        this.form.reset({
          nombre: '',
          telefono: '',
          correo: '',
          mensaje: '',
          presupuesto : ''
        });
      }, err => this.proyectosService.showToastr('error', err));
    } catch(err) {
      this.proyectosService.showToastr('error', err);
    }
  }
  }
