import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const routes: Routes = [
      { path: '', redirectTo: 'inicio', pathMatch: 'full'},
      {
        path: 'inicio',
        loadChildren: './components/inicio/inicio.module#InicioModule',
      },
      {
        path: 'proyectos',
        loadChildren: './components/proyectos/proyectos.module#ProyectosModule',
      },
      {path: '404', component: NotFoundComponent},
      { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
