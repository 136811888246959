import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Globals } from '../../globals';
import { catchError } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
@Injectable({
  providedIn: 'root'
})
export class ProyectosService {

  constructor(private toastr: ToastrManager,private http: HttpClient, private globals: Globals, ) { }

  getAllProyectos(tipo, skip) {
    return this.http.get(`${this.globals.apiUrl}/proyectos/getAllFront?tipo=${tipo}&skip=${skip}`).pipe( catchError(this.handleError));
  }
  getProyecto(nombre) {
    return this.http.get(`${this.globals.apiUrl}/proyectos/getProyectoDetalle?nombre=${nombre}`).pipe( catchError(this.handleError));
  }
  enviarFormulario(info) {
    return this.http.post(`${this.globals.apiUrl}/contacto/enviarContacto`, info).pipe( catchError(this.handleError));
  }
  showToastr(tipo,mensaje) {
    return tipo === 'error' ? this.toastr.errorToastr(mensaje, '', {toastTimeout: 2000,maxShown: 1, position: 'bottom-right'}) :
    this.toastr.successToastr(mensaje, '', {toastTimeout: 2000,maxShown: 1, position: 'bottom-right'});
  }
  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  };
}
